<template>
  <!-- 学生出勤-大课间 -->
  <div class="container">
    <div class="main">
      <div class="header_box flexSb">
        <div class="flexSt list_box">
          <div class="flexSt list list2">
            <div class="title">年级/班级</div>
            <el-cascader
              :options="options"
              v-model="form.grade"
              :props="{ children: 'class', multiple: true }"
              @change="handleChange"
              filterable
              clearable></el-cascader>
          </div>
          <div class="flexSt list">
            <div class="title">学生姓名</div>
            <el-input
              clearable
              v-model="form.name"
              placeholder="请输入学生姓名"></el-input>
          </div>

          <div class="flexSt list">
            <div class="title">性别</div>
            <el-select
              @change="handleSearch"
              v-model="form.gender"
              placeholder="请选择性别">
              <el-option
                v-for="(item, index) in genderlist"
                :key="index"
                :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </div>

          <div class="flexSt list time">
            <div class="title">大课间时间</div>
            <el-date-picker
              @change="handleChangeTime"
              v-model="value2"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </div>
          <div class="flexSt list">
            <div class="title">状态</div>
            <el-select
              @change="handleSearch"
              v-model="form.attendance_status"
              placeholder="请选择状态">
              <el-option
                v-for="(item, index) in attendancelist"
                :key="index"
                :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </div>

          <div class="flexSb list">
            <el-button
              @click="handleSearch"
              type="primary"
              icon="el-icon-search"
              >搜索</el-button
            >
            <el-button @click="handleclearSearch" icon="el-icon-refresh"
              >重置</el-button
            >
          </div>
        </div>
      </div>
      <!-- <div class="add flexSb">
      <div class="flexSt">
        <el-button type="primary" icon="el-icon-plus">添加出勤</el-button>
        <el-button icon="el-icon-delete">删除</el-button>
      </div>
      <div class="flexSb">
        <el-button icon="el-icon-download">导入</el-button>
        <el-button icon="el-icon-upload2">导出</el-button>
      </div>
    </div> -->
      <div class="table_box">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          :header-cell-style="tableHeaderColor"
          :header-cell-class-name="cellClass"
          :cell-class-name="returnName">
          <el-table-column type="selection" width="25"> </el-table-column>
          <el-table-column
            :index="indexMethod"
            type="index"
            label="序号"
            width="60"
            align="center">
          </el-table-column>
          <el-table-column label="学生" width="170" align="center">
            <template slot-scope="scope">
              <div class="flexSc">
                <a href="" v-if="scope.row.user_pic">
                  <img class="img" :src="scope.row.user_pic" alt=""
                /></a>
                <a href="">
                  <img
                    v-if="scope.row.gender == 0"
                    class="img"
                    src="../../../assets/img/1633.png"
                    alt="" />
                  <img
                    v-else
                    class="img"
                    src="../../../assets/img/1634.png"
                    alt="" />
                </a>
                <div>
                  <div class="flexSt">
                    <a href="">
                      <div class="name">
                        {{
                          scope.row.student_name
                            ? scope.row.student_name
                            : "- -"
                        }}
                      </div>
                    </a>
                    <img
                      v-if="scope.row.gender == 1"
                      class="img_gender"
                      src="../../../assets/img/206.png"
                      alt="" />
                    <img
                      v-else
                      class="img_gender"
                      src="../../../assets/img/207.png"
                      alt="" />
                  </div>
                  <div>
                    班号：
                    {{ scope.row.number ? scope.row.number : "- -" }}
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="所在班级" align="center" min-width="110">
            <template slot-scope="scope">
              <div>
                {{ scope.row.class_name ? scope.row.class_name : "- -" }}
              </div>
            </template></el-table-column
          >
          <el-table-column
            prop="teacher_name"
            align="center"
            label="教师"
            min-width="120">
            <template slot-scope="scope">
              <div>
                {{ scope.row.teacher_name ? scope.row.teacher_name : "- -" }}
              </div>
            </template></el-table-column
          >

          <el-table-column
            prop="class_start_time"
            label="大课间时间"
            sortable
            min-width="140"
            align="center">
            <template slot-scope="scope">
              <div style="padding-right: 16px">
                {{
                  scope.row.class_start_time
                    ? scope.row.class_start_time +
                      "至" +
                      scope.row.class_end_time
                    : "- -"
                }}
              </div>
            </template>
          </el-table-column>

          <el-table-column label="状态" align="center">
            <template slot-scope="scope">
              <div>
                <el-tag v-if="scope.row.attendance_status == 1">出勤</el-tag>

                <!-- <el-tag type="danger" v-if="scope.row.attendance_status == 2">请假</el-tag> -->
                <el-tag type="info" v-if="scope.row.attendance_status == 0"
                  >缺勤</el-tag
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column label="备注" align="center" min-width="100">
            <template slot-scope="scope">
              <div>
                {{ scope.row.remark ? scope.row.remark : "- -" }}
              </div>
            </template></el-table-column
          >
          <!-- <el-table-column width="120" fixed="right" label="操作" align="center">
          <template slot-scope="scope">
            <div class="flexSc">
              <el-button type="text">查看</el-button>
              <el-button type="text">编辑</el-button>
            </div>
          </template>
        </el-table-column> -->
        </el-table>

        <div class="flexSe elpla">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageInfo.page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageInfo.total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { gradeClassList } from "@/api/evaluation";
import { studentCourseAttendance } from "@/api/classroom";
export default {
  data() {
    return {
      school_id: "",
      options: [],
      attendancelist: [
        {
          label: "全部",
          value: -1,
        },
        {
          label: "未出勤",
          value: 0,
        },
        {
          label: "已出勤",
          value: 1,
        },
        {
          label: "待出勤",
          value: 2,
        },
      ],
      genderlist: [
        {
          label: "全部",
          value: -1,
        },
        {
          label: "男",
          value: 1,
        },
        {
          label: "女",
          value: 0,
        },
      ],
      value2: [],
      form: {
        grade: [],
        attendance_status: -1,
        start_time: "",
        end_time: "",
        class_id: "",
        gender: -1,
        name: "",
        teacher_id: "",
      },
      value1: [],
      pageInfo: {
        pageSize: 10,
        page: 1,
        total: 0,
      },
      tableData: [],
      teacherlist: [],
    };
  },
  computed: {
    indexMethod() {
      // 当前页数 - 1 * 每页数据条数 + 1
      return (this.pageInfo.page - 1) * this.pageInfo.pageSize + 1;
    },
  },
  mounted() {
    this.school_id = JSON.parse(localStorage.getItem("user")).school_id;
    this.handleGetGradeClassList();
    this.handleGetstudentCourseAttendance();
  },
  methods: {
    handleChangeTime(val) {
      if (val) {
        this.form.start_time = val[0];
        this.form.end_time = val[0];
      } else {
        this.form.start_time = "";
        this.form.end_time = "";
      }
      this.handleSearch();
    },
    // 年级班级
    async handleGetGradeClassList() {
      await gradeClassList().then((res) => {
        this.options = res.data;
      });
    },
    handleChange(val) {
      if (val.length > 0) {
        if (val.length == 1) {
          this.form.class_id = val[0][1];
        } else {
          let class_id = [];
          val.forEach((item, index) => {
            class_id.push(item[1]);
          });
          this.form.class_id = class_id.join(",");
        }
      } else {
        this.form.class_id = "";
      }
      this.handleSearch();
    },
    async handleGetstudentCourseAttendance() {
      let data = {
        start_time: this.form.start_time,
        end_time: this.form.end_time,
        attendance_status: this.form.attendance_status,
        name: this.form.name,
        course_type: 2,
        class_id: this.form.class_id,
        per_page: this.pageInfo.pageSize,
        page: this.pageInfo.page,
        gender: this.form.gender,
        teacher_id: this.teacher_id,
      };
      await studentCourseAttendance(data).then((res) => {
        this.tableData = res.data.data;
        this.pageInfo.total = res.data.total;
      });
    },
    returnName(obj) {
      if (obj.columnIndex == 0) {
        return "firstCell";
      }
    },
    handleSearch() {
      this.pageInfo.page = 1;
      this.handleGetstudentCourseAttendance();
    },
    handleclearSearch() {
      this.form = {
        grade: [],
        attendance_status: -1,
        start_time: "",
        end_time: "",
        class_id: "",
        gender: -1,
        name: "",
        teacher_id: "",
      };
      this.value2 = [];
      this.handleGetstudentCourseAttendance();
    },
    handleSelectionChange() {},
    cellClass() {
      return "disableSelection";
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      return {
        fontSize: "16px",
        color: "#333333",
        paddingTop: "8px",
        paddingBottom: "8px",
        backgroundColor: "#F7F7F7",
      };
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.pageInfo.page = 1;
      this.handleGetstudentCourseAttendance();
    },
    // 翻页
    handleCurrentChange(val) {
      this.pageInfo.page = val;
      this.handleGetstudentCourseAttendance();
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: stretch;
  padding: 20px;
  box-sizing: border-box;
  min-height: 100%;
}
.main {
  flex: 1;
  background-color: #fff;
  border-radius: 4px;
  padding: 35px 25px;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
}
.container {
  /deep/.firstCell {
    .cell {
      overflow: unset !important;
    }
  }
  .table_box {
    /deep/.tableImg {
      /deep/.cell {
        height: 60px;
      }
    }
    .table_img {
      border-radius: 10px;
      width: 80px;
    }
    .img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 14px;
    }
    .img_gender {
      width: 20px;
      height: 20px;
    }
    .name {
      font-size: 14px;
      color: #1890ff;
      margin-right: 6px;
    }
  }
  .list_box {
    flex-wrap: wrap;
  }
  .elpla {
    margin-top: 30px;
  }
  .header_box {
    border-bottom: 1px solid rgba(242, 242, 242, 1);
    padding-bottom: 20px;
    box-sizing: border-box;
    flex-wrap: wrap;
    .list {
      margin-bottom: 10px;
    }
    /deep/.title {
      color: rgb(78, 89, 105);
      font-size: 14px;
      margin-right: 8px;
      // min-width: 60px;
    }
    .list2 {
      /deep/.el-cascader {
        width: 147px !important;
        margin-right: 10px;
      }
    }
    .sex {
      /deep/.el-form-item__label {
        width: 47px !important;
      }
      /deep/.el-form-item__content {
        margin-left: 60px !important;
      }
      /deep/.el-select {
        width: 127px !important;
        height: 32px !important;
      }
      /deep/.el-form-item__content {
        width: 127px !important;
      }
    }
    .sex2 {
      /deep/.el-form-item__label {
        width: 80px !important;
      }
      /deep/.el-form-item__content {
        width: 167px !important;
      }
      /deep/.el-select {
        width: 147px !important;
        height: 32px !important;
      }
    }
    .sex3 {
      /deep/.el-select,
      /deep/.el-input__inner,
      /deep/.el-input {
        width: 127px !important;
        height: 32px !important;
      }
      /deep/.el-form-item__content {
        // margin-left: 40px !important;
      }
    }
    /deep/.el-button {
      padding: 0 20px;
      // height: 30px !important;
      // line-height: 30px;
      height: 40px !important;
      line-height: 40px;
    }

    /deep/.el-form-item__content,
    /deep/.el-form-item__labe {
      height: 32px !important;
      width: 147px;
    }
    // /deep/.el-input__inner,   /deep/.el-input,
    /deep/.el-input {
      margin-right: 15px;
    }
    /deep/.el-input__inner,
    /deep/.el-input {
      width: 147px;
    }

    /deep/.el-input__icon {
      line-height: 32px !important;
    }

    /deep/.el-form-item__content {
      width: 171px !important;
    }
    /deep/.el-date-editor {
      width: 357px;
    }
    /deep/ .time {
      margin-right: 10px;
      .el-input__icon {
        line-height: 25px !important;
      }
      .el-date-editor {
        .el-range-separator {
          line-height: 25px !important;
        }
      }
    }
  }

  .add {
    margin: 17px 0;
    /deep/.el-button {
      padding: 0 20px;
      height: 30px !important;
      line-height: 30px;
    }
  }
}
</style>
